import styled from '@emotion/styled'
import Text from 'components/Utilities/Text'
import Title from 'components/Utilities/Title'
import Image from 'gatsby-image'
import LinkFormatter from 'components/LinkFormatter'

export const RelatedArticlesContainer = styled(LinkFormatter)`
  display: flex;
  justify-content: space-between;
  padding-top: 120px;
  padding-bottom: 120px;
  width: calc(50% - 15px);
  max-width: 50%;
  text-decoration: none;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
    max-width: 100%;

    &:first-of-type {
      padding-bottom: 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (max-width: 450px) {
    display: initial;
  }

  &.condensed {
    align-items: flex-end;

    .RelatedArticleContent {
      width: calc(100% - 200px);
      border-bottom: 1px solid ${props => props.theme.colors.primarygreen};
      padding-right: 25px;

      @media (max-width: 450px) {
        width: 100%;
        padding-right: 0;
        border-bottom: 0;
      }
    }
    .RelatedArticleImage {
      max-width: 200px;
      height: 100%;
    }
    .EstimatedReadTimeText {
      width: auto;

      @media (max-width: 450px) {
        margin-top: 0;
      }
    }
    .DateText {
      width: auto;
      margin-right: 50px;
      margin-bottom: 10px;

      @media (max-width: 450px) {
        margin-bottom: 0;
      }
    }
  }
`

export const RelatedArticle = styled.article``

export const RelatedArticleContent = styled.div`
  width: calc(50% - 10px);
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 450px) {
    width: 100%;
    margin-bottom: 30px;
  }
`

export const RelatedArticleImage = styled(Image)`
  width: calc(50% - 10px);
  max-height: 300px;

  @media (max-width: 450px) {
    max-width: initial;
    width: 100%;
  }
`

export const TagText = styled(Text)`
  width: 100%;
  margin-bottom: 20px;
  text-transform: uppercase;
`

export const RelatedArticleTitle = styled(Text)`
  width: 100%;
  margin-bottom: 10px;
`

export const RelatedArticleTitleCondensed = styled(Title)`
  width: 100%;
  margin-bottom: 10px;
`

export const ExcerptText = styled(Text)`
  width: 100%;
  font-weight: 300;
  margin-bottom: 25px;
`

export const DateText = styled(Text)`
  width: 50%;
  font-weight: 300;
  opacity: 0.45;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    width: 100%;
  }
`

export const EstimatedReadTimeText = styled(Text)`
  width: 50%;
  text-align: right;
  font-weight: 300;
  opacity: 0.45;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    width: 100%;
    text-align: left;
    margin-top: 10px;
  }
`

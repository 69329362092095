import styled from '@emotion/styled'

export const ContainerStyled = styled.div`
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 50px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    padding: 0 100px;
  }

  @media (min-width: 1440px) {
    padding: 0 186px;
  }
`

import React from 'react'
import {
  RelatedArticlesContainer,
  RelatedArticleContent,
  RelatedArticleImage,
  RelatedArticleTitle,
  TagText,
  ExcerptText,
  DateText,
  EstimatedReadTimeText,
  RelatedArticleTitleCondensed,
} from './styles'
import readingTime from 'reading-time'

const ArticleTile = ({ node, estimated_read_time, condensed }) => {
  const stats = node?.data?.content.html
    ? readingTime(node?.data?.content.html)
    : ''

  return (
    <RelatedArticlesContainer
      className={condensed ? 'condensed' : ''}
      pageHandle={node?.uid}
      pageType={node?.data?.page_type}
    >
      <RelatedArticleContent className="RelatedArticleContent">
        {node?.data?.tags?.text && !condensed ? (
          <TagText type="tertiary-small">
            {node?.data?.tags?.text.includes(',')
              ? node?.data?.tags?.text?.split(',')?.[0]?.trim()
              : node?.data?.tags?.text}
          </TagText>
        ) : (
          <></>
        )}
        {node?.data?.title?.text && !condensed ? (
          <RelatedArticleTitle type="primary" as="h2">
            {node?.data?.title?.text}
          </RelatedArticleTitle>
        ) : node?.data?.title?.text && condensed ? (
          <RelatedArticleTitleCondensed type="h4">
            {node?.data?.title?.text}
          </RelatedArticleTitleCondensed>
        ) : (
          <RelatedArticleTitle type="primary" as="h2">
            {node?.data?.title?.text}
          </RelatedArticleTitle>
        )}
        {node?.data?.excerpt?.text ? (
          <ExcerptText type="tertiary-small">
            {node?.data?.excerpt?.text}
          </ExcerptText>
        ) : (
          <></>
        )}
        <DateText className="DateText" type="tertiary-small">
          {node?.data?.date}
        </DateText>
        {stats && (
          <EstimatedReadTimeText
            className="EstimatedReadTimeText"
            type="tertiary-small"
          >{`${Math.ceil(stats.minutes)} ${
            estimated_read_time?.text
          }`}</EstimatedReadTimeText>
        )}
      </RelatedArticleContent>
      {node?.data?.main_image?.localFile?.childImageSharp?.fluid && (
        <RelatedArticleImage
          className="RelatedArticleImage"
          fluid={{
            ...node?.data?.main_image?.localFile?.childImageSharp?.fluid,
            aspectRatio: 1 / 1,
          }}
          alt={node?.data?.main_image?.alt}
        />
      )}
    </RelatedArticlesContainer>
  )
}

export default ArticleTile
